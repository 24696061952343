import {
  GET_AUDIT_LOG,
  GET_AUDIT_LOG_SUCCESS,
  GET_AUDIT_LOG_ERROR,
  GET_TIMEKEEPER_AUDIT_LOG,
  GET_TIMEKEEPER_AUDIT_LOG_SUCCESS,
  GET_TIMEKEEPER_AUDIT_LOG_ERROR,
  GET_TIMEKEEPER_AUDIT_LOG_EXPORT_SUCCESS,
  GET_TIMEKEEPER_AUDIT_LOG_EXPORT_RESET,
} from "../actions";

export const getAuditLog = (data) => ({
  type: GET_AUDIT_LOG,
  payload: data,
});
export const getAuditLogSuccess = (data) => ({
  type: GET_AUDIT_LOG_SUCCESS,
  payload: data,
});
export const getAuditLogError = (error) => ({
  type: GET_AUDIT_LOG_ERROR,
  payload: error,
});
export const getTimekeeperAuditLog = (data) => ({
  type: GET_TIMEKEEPER_AUDIT_LOG,
  payload: data,
});
export const getTimekeeperAuditLogSuccess = (data) => ({
  type: GET_TIMEKEEPER_AUDIT_LOG_SUCCESS,
  payload: data,
});

export const getTimekeeperAuditLogError = (error) => ({
  type: GET_TIMEKEEPER_AUDIT_LOG_ERROR,
  payload: error,
});
export const getTimekeeperAuditLogExportSuccess = (data) => ({
  type: GET_TIMEKEEPER_AUDIT_LOG_EXPORT_SUCCESS,
  payload: data,
});
export const getTimekeeperAuditLogExportReset = (data) => ({
  type: GET_TIMEKEEPER_AUDIT_LOG_EXPORT_RESET,
  payload: data,
});
