export const managerDataById = [
    {
        name: 'JOHN BOND',
        role: 'Supervisor',
        managerName: 'pegger Philips',
        organization:'online contact center',
        occurrence: [
            {
                date: '9/3/12',
                person: 'Teressa',               
                occurrence: 'Tardy',
                value: 'resolved'
            },
            {
                date: '9/3/12',
                person: 'Teressa',
                occurrence: 'Tardy',
                value: 'resolved'
            },
            {
                date: '9/3/12',
                person: 'Teressa',
                occurrence: 'Tardy',
                value: 'resolved'
            },
            {
                date: '9/3/12',
                person: 'Teressa',
                occurrence: 'Tardy',
                value: 'resolved'
            },
        ]

    },
   

]
export default managerDataById

