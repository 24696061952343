import {
  GET_AUDIT_LOG,
  GET_AUDIT_LOG_SUCCESS,
  GET_AUDIT_LOG_ERROR,
  GET_TIMEKEEPER_AUDIT_LOG,
  GET_TIMEKEEPER_AUDIT_LOG_SUCCESS,
  GET_TIMEKEEPER_AUDIT_LOG_ERROR,
  GET_TIMEKEEPER_AUDIT_LOG_EXPORT_SUCCESS,
  GET_TIMEKEEPER_AUDIT_LOG_EXPORT_RESET,
} from "../actions";

const INIT_STATE = {
  auditLogList: null,
  auditLogListLoading: false,
  auditLogListError: "",
  timekeeperAuditLogList: [],
  timekeeperAuditLogLoading: false,
  timekeeperAuditLogError: "",
  exportedTimekeeperAuditLog: [],
};

const auditLogReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIT_LOG:
      return {
        ...state,
        auditLogList: null,
        auditLogListLoading: true,
        auditLogListError: "",
      };
    case GET_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        auditLogList: action.payload,
        auditLogListLoading: false,
        auditLogListError: "",
      };
    case GET_AUDIT_LOG_ERROR:
      return {
        ...state,
        auditLogListLoading: false,
        auditLogListError: action.payload,
      };
    case GET_TIMEKEEPER_AUDIT_LOG:
      return {
        ...state,
        timekeeperAuditLogLoading: true,
        timekeeperAuditLogError: "",
      };
    case GET_TIMEKEEPER_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        timekeeperAuditLogList: action.payload,
        timekeeperAuditLogLoading: false,
        timekeeperAuditLogError: "",
      };
    case GET_TIMEKEEPER_AUDIT_LOG_ERROR:
      return {
        ...state,
        timekeeperAuditLogLoading: false,
        timekeeperAuditLogError: action.payload,
      };
    case GET_TIMEKEEPER_AUDIT_LOG_EXPORT_SUCCESS:
      return {
        ...state,
        timekeeperAuditLogLoading: false,
        exportedTimekeeperAuditLog: action.payload,
      };
    case GET_TIMEKEEPER_AUDIT_LOG_EXPORT_RESET:
      return {
        ...state,
        exportedTimekeeperAuditLog: [],
      };
    default:
      return { ...state };
  }
};
export default auditLogReducer;
