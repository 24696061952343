import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  ADD_OCCURRENCE,
  GET_ATTENDANCE_PERSON_DETAILS,
  ASSIGN_OCCURRENCE,
  DELETE_OCCURRENCE,
  DELETE_REASON_DROPDOWN,
  EDIT_OCCURRENCE,
  getOccurrenceAction,
  GET_EXCUSE_DROPDOWN_ACTION,
  GET_MANAGER_BY_ID,
  GET_OCCURRENCE,
  GET_OCCURRENCE_DROPDOWN_LIST,
  GET_PERSON_LIST,
  // IGNORE_OCCURRENCE,
  MARK_EXCUSED,
  MARK_RESOLVED,
  UNDO_RESOLUTION,
  GET_PERSON_BY_ID,
  GET_PENDING_OCCURRENCE,
  // UPDATE_PENDING_OCCURRENCE,
  GET_ATTENDANCE_SUMMARY,
  GET_ATTENDANCE_PERMISSION,
  GET_ATTENDANCE_EXPORT,
} from "../actions";

import {
  getOccurrenceDropdownListSuccess,
  getOccurrenceDropdownListError,
  getExcuseDropdownSuccess,
  getExcuseDropdownError,
  getPersonListSuccess,
  getPersonListError,
  getOccurrenceSuccess,
  getOccurrenceError,
  getManagerDataByIdSuccess,
  getManagerDataByIdError,
  addOccurrenceSuccess,
  addOccurrenceError,
  deleteReasonDropdownSuccess,
  deleteReasonDropdownError,
  editOccurrenceSuccess,
  editOccurrenceError,
  markExcusedSuccess,
  markExcusedError,
  markResolvedSuccess,
  markResolvedError,
  deleteOccurrenceSuccess,
  deleteOccurrenceError,
  undoResolutionSuccess,
  undoResolutionError,
  assignOccurrenceSuccess,
  assignOccurrenceError,
  // ignoreOccurrenceSuccess,
  // ignoreOccurrenceError,
  getPersonByIdSuccess,
  getPersonByIdError,
  getPendingOccurrenceSuccess,
  getPendingOccurrenceError,
  // updatePendingOccurrenceSuccess,
  // updatePendingOccurrenceError,
  getAttendanceSummarySuccess,
  getAttendanceSummaryError,
  getPendingOccurrenceAction,
  getPersonByIdAction,
  getAttendancePermissionSuccess,
  getAttendancePermissionError,
  getAttendanceExportAction,
  getAttendanceExportSuccess,
  getAttendanceExportError,
} from "./actions";
import {
  getApiHeader,
  getCompanyGUID,
  getLoginPersonId,
} from "../../helpers/utils";
import managerDataById from "../../data/managerDataById";
import deleteReasonDropdowndata from "../../data/deleteReasonDropdown";
import { DataEntryAPI } from "../../config/api";

export function* watchPersonListEntry() {
  yield takeEvery(GET_PERSON_LIST, getPersonListCall);
}

export function* watchOccurrenceDropdown() {
  yield takeEvery(GET_OCCURRENCE_DROPDOWN_LIST, getOccurrenceDropdownCall);
}
export function* watchGetPersonById() {
  yield takeEvery(GET_PERSON_BY_ID, getPersonByIdCall);
}

export function* watchExcuseDropdown() {
  yield takeEvery(GET_EXCUSE_DROPDOWN_ACTION, getExcuseDropdownCall);
}

export function* watchAddOccurrenceEntry() {
  yield takeEvery(ADD_OCCURRENCE, addOccurrenceCall);
}

export function* watchGetOccurrenceEntry() {
  yield takeEvery(GET_OCCURRENCE, getOccurrenceCall);
}

export function* watchGetAttendanceSummary() {
  yield takeEvery(GET_ATTENDANCE_SUMMARY, getAttendanceSummaryCall);
}

export function* watchGetMangerByIdEntry() {
  yield takeEvery(GET_MANAGER_BY_ID, getManagerByIdCall);
}

export function* watchDeleteReasonDropdown() {
  yield takeEvery(DELETE_REASON_DROPDOWN, deleteReasonDropdownCall);
}

export function* watchEditOccurrence() {
  yield takeEvery(EDIT_OCCURRENCE, editOccurrenceCall);
}

export function* watchMarkExcused() {
  yield takeEvery(MARK_EXCUSED, markExcusedCall);
}

export function* watchMarkResolved() {
  yield takeEvery(MARK_RESOLVED, markResolvedCall);
}

export function* watchDeleteOccurrence() {
  yield takeEvery(DELETE_OCCURRENCE, deleteOccurrenceCall);
}

export function* watchUndoResolution() {
  yield takeEvery(UNDO_RESOLUTION, undoResolutionCall);
}

export function* watchGetPendingOccurrence() {
  yield takeEvery(GET_PENDING_OCCURRENCE, getPendingOccurrenceCall);
}

// export function* watchUpdatePendingOccurrence() {
//     yield takeEvery(UPDATE_PENDING_OCCURRENCE, updatePendingOccurrenceCall);
// }

export function* watchAssignOccurrence() {
  yield takeEvery(ASSIGN_OCCURRENCE, assignOccurrenceCall);
}

// export function* watchIgnoreOccurrence()
// {
//     yield takeEvery(IGNORE_OCCURRENCE, ignoreOccurrenceCall);
// }

export function* watchAttendancePermission() {
  yield takeEvery(GET_ATTENDANCE_PERMISSION, getAttendancePermissionCall);
}

export function* watchAttendanceExport() {
  yield takeEvery(GET_ATTENDANCE_EXPORT, attendanceExportCall);
}

function* getPersonListCall({ payload }) {
  try {
    const data = yield call(getPersonListCallAsync, payload);
    yield put(getPersonListSuccess(data));
  } catch (error) {
    yield put(getPersonListError(error));
  }
}

function* getOccurrenceDropdownCall() {
  try {
    const data = yield call(getOccurrenceDropdownCallAsync);
    yield put(getOccurrenceDropdownListSuccess(data));
  } catch (error) {
    yield put(getOccurrenceDropdownListError(error));
  }
}
function* getPersonByIdCall({ payload }) {
  try {
    const data = yield call(getPersonByIdCallAsync, payload);
    yield put(getPersonByIdSuccess(data));
  } catch (error) {
    yield put(getPersonByIdError(error));
  }
}

function* getExcuseDropdownCall() {
  try {
    const data = yield call(getExcuseDropdownCallAsync);
    yield put(getExcuseDropdownSuccess(data));
  } catch (error) {
    yield put(getExcuseDropdownError(error));
  }
}

function* addOccurrenceCall({ payload }) {
  try {
    const data = yield call(addOccurrenceCallAsync, payload);
    yield put(getOccurrenceAction(1, 15, "desc"));
    yield put(addOccurrenceSuccess(data));
  } catch (error) {
    yield put(addOccurrenceError(error));
  }
}

function* getOccurrenceCall({ payload }) {
  try {
    const data = yield call(getOccurrenceCallAsync, payload);
    yield put(getOccurrenceSuccess(data));
  } catch (error) {
    yield put(getOccurrenceError(error));
  }
}

function* getAttendanceSummaryCall({ payload }) {
  try {
    const data = yield call(getAttendanceSummaryCallAsync, payload);
    yield put(getAttendanceSummarySuccess(data));
  } catch (error) {
    yield put(getAttendanceSummaryError(error));
  }
}

function* getManagerByIdCall({ payload }) {
  try {
    const data = yield call(getManagerByIdCallAsync, payload);
    yield put(getManagerDataByIdSuccess(data));
  } catch (error) {
    yield put(getManagerDataByIdError(error));
  }
}

function* deleteReasonDropdownCall({ payload }) {
  try {
    const data = yield call(deleteReasonDropdownCallAsync, payload);
    yield put(deleteReasonDropdownSuccess(data));
  } catch (error) {
    yield put(deleteReasonDropdownError(error));
  }
}

function* editOccurrenceCall({ payload }) {
  try {
    const data = yield call(editOccurrenceCallAsync, payload);
    yield put(editOccurrenceSuccess(data));
    yield put(getPersonByIdAction(payload.personId));
  } catch (error) {
    yield put(editOccurrenceError(error));
  }
}

function* markExcusedCall({ payload }) {
  try {
    const data = yield call(markExcusedCallAsync, payload);
    yield put(markExcusedSuccess(data));
    yield put(getPersonByIdAction(payload.personId));
  } catch (error) {
    yield put(markExcusedError(error));
  }
}

function* markResolvedCall({ payload }) {
  try {
    const data = yield call(markResolvedCallAsync, payload);
    yield put(markResolvedSuccess(data));
    yield put(getPersonByIdAction(payload[0]?.personId));
  } catch (error) {
    yield put(markResolvedError(error));
  }
}

function* deleteOccurrenceCall({ payload }) {
  try {
    const data = yield call(deleteOccurrenceCallAsync, payload);
    yield put(deleteOccurrenceSuccess(data));
    yield put(getPersonByIdAction(payload.personId));
  } catch (error) {
    yield put(deleteOccurrenceError(error));
  }
}

function* undoResolutionCall({ payload }) {
  try {
    const data = yield call(undoResolutionCallAsync, payload);
    yield put(undoResolutionSuccess(data));
  } catch (error) {
    yield put(undoResolutionError(error));
  }
}

function* getPendingOccurrenceCall({ payload }) {
  try {
    const data = yield call(getPendingOccurrenceCallAsync, payload);
    yield put(getPendingOccurrenceSuccess(data));
  } catch (error) {
    yield put(getPendingOccurrenceError(error));
  }
}
function* getAttendancePermissionCall() {
  try {
    const data = yield call(getAttendancePermissionCallAsync);
    yield put(getAttendancePermissionSuccess(data));
  } catch (error) {
    yield put(getAttendancePermissionError(error));
  }
}

// function* updatePendingOccurrenceCall({ payload }) {
//     try {
//         const data = yield call(updatePendingOccurrenceCallAsync, payload);
//         yield put(updatePendingOccurrenceSuccess(data));
//     } catch (error) {
//         yield put(updatePendingOccurrenceError(error));
//     }
// }

function* assignOccurrenceCall({ payload }) {
  try {
    const data = yield call(assignOccurrenceCallAsync, payload);
    // yield put(getPendingOccurrenceAction());
    yield put(assignOccurrenceSuccess(data));
  } catch (error) {
    yield put(assignOccurrenceError(error));
  }
}

function* attendanceExportCall({ payload }) {
  try {
    const data = yield call(getAttendanceExportCallAsync, payload);
    yield put(getAttendanceExportSuccess(data));
  } catch (error) {
    yield put(getAttendanceExportError(error));
  }
}

// function* ignoreOccurrenceCall({ payload })
// {
//     try {
//         const data = yield call(ignoreOccurrenceCallAsync, payload);
//         yield put(ignoreOccurrenceSuccess(data));
//     } catch (error) {
//         yield put(ignoreOccurrenceError(error));
//     }
// }

const getPersonListCallAsync = (payload) => {
  // return personListData
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendancePersons?companyguid=${getCompanyGUID()}&searchname=${payload}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const getOccurrenceDropdownCallAsync = (payload) => {
  // return occurrenceDropdownData
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendanceOccurence?companyguid=${getCompanyGUID()}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};
const getPersonByIdCallAsync = (payload) => {
  var urlIs = "";
  if (payload.state) {
    urlIs = `${DataEntryAPI}/GetAttendacePersonDetails?companyguid=${getCompanyGUID()}&PersonId=${payload?.id
      }&screenName=viewoccurance&screennavigaton=RTA`;
  } else {
    urlIs = `${DataEntryAPI}/GetAttendacePersonDetails?companyguid=${getCompanyGUID()}&PersonId=${payload?.id
      }&screenName=viewoccurance`;
  }
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: urlIs,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};
const getExcuseDropdownCallAsync = () => {
  // return excuseDropdownData
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendanceExecuse?companyguid=${getCompanyGUID()}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const addOccurrenceCallAsync = (payload) => {
  payload.companyguid = getCompanyGUID();
  payload.CreatedbyPersonID = getLoginPersonId();
  return axios({
    method: "POST",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/InsertAttendanceOccurence`,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const getOccurrenceCallAsync = (payload) => {
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendanceOccurrences?companyGuid=${getCompanyGUID()}&PersonID=${getLoginPersonId()}&PageNumber=${payload?.page
      }&RowsOfPage=${payload?.size}&Direction=${payload?.direction}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const getAttendanceSummaryCallAsync = (payload) => {
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendaceSummary?companyguid=${getCompanyGUID()}&LoginPersonId=${getLoginPersonId()}&searchname=${payload}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const getManagerByIdCallAsync = (payload) => {
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendaceManagerPersonDetails?companyguid=${getCompanyGUID()}&ManagerPersonID=${payload}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err?.message);
      return err;
    });
};

const deleteReasonDropdownCallAsync = (payload) => {
  return deleteReasonDropdowndata;
};

const editOccurrenceCallAsync = (payload) => {
  return axios({
    method: "POST",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/UpdateOccuranceEmployeeDetails`,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const markExcusedCallAsync = (payload) => {
  return axios({
    method: "POST",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/UpdateMarkExcusedOccuranceEmployeeDetails`,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const markResolvedCallAsync = (payload) => {
  return axios({
    method: "POST",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/updateattendanceoccurancemarkasresolved`,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const deleteOccurrenceCallAsync = (payload) => {
  return axios({
    method: "DELETE",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/deleteOccuranceEmployeeDetails?ID=${payload.id}&deletedreason=${payload.deletedreason}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

const undoResolutionCallAsync = (payload) => {
  return "undoResolution";
};

const getPendingOccurrenceCallAsync = (payload) => {
  return axios({
    method: "POST",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetPendingOccurances?companyGuid=${getCompanyGUID()}&PersonID=${getLoginPersonId()}&PageNumber=${payload?.page
      }&RowsOfPage=${payload?.size}&Direction=${payload?.direction}&sortingprop=${payload?.sortFor
      }`,
    data: payload?.filter,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};
const getAttendancePermissionCallAsync = () => {
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAttendanceTabsPermissions?companyGuid=${getCompanyGUID()}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

// const updatePendingOccurrenceCallAsync = (payload) => {
//     return axios({
//         method: 'GET',
//         headers: getApiHeader(),
//         url: `${DataEntryAPI}/UpdateAttendancePendingOccurrance?companyGuid=${getCompanyGUID()}&PersonID=${getLoginPersonId()}`
//     }).then((res) => res.data)
//         .catch((err) => {
//             toast.error(err)
//             return err;
//         });
// }

const assignOccurrenceCallAsync = (payload) => {
  return axios({
    method: "POST",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/UpdateAttendancePendingOccurrance`,
    data: payload,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

// const ignoreOccurrenceCallAsync = (payload) =>
// {
//     return 'ignoreOccurrence'
// }



//attendanceExport

const getAttendanceExportCallAsync = (payload) => {
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/ManageExport?exportDate=${payload.exportDate}&exportType=${payload.exportType}`,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      return err;
    });
};

export default function* rootSaga() {
  yield all([
    fork(watchOccurrenceDropdown),
    fork(watchGetPersonById),
    fork(watchExcuseDropdown),
    fork(watchPersonListEntry),
    fork(watchAddOccurrenceEntry),
    fork(watchGetOccurrenceEntry),
    fork(watchGetAttendanceSummary),
    fork(watchGetMangerByIdEntry),
    fork(watchDeleteReasonDropdown),
    fork(watchEditOccurrence),
    fork(watchMarkExcused),
    fork(watchMarkResolved),
    fork(watchDeleteOccurrence),
    fork(watchUndoResolution),
    fork(watchGetPendingOccurrence),
    // fork(watchUpdatePendingOccurrence),
    fork(watchAssignOccurrence),
    // fork(watchIgnoreOccurrence),
    fork(watchAttendancePermission),
    fork(watchAttendanceExport)
  ]);
}
