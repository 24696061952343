import {
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  GET_ORGANIZATION_DETAILS_ERROR,
  GET_ORG_BASED_ROSTER_DATA,
  GET_ORG_BASED_ROSTER_DATA_SUCCESS,
  GET_ORG_BASED_ROSTER_DATA_ERROR,
  TOGGLE_ROSTER,
  TOGGLE_ROSTER_SUCCESS,
  TOGGLE_ROSTER_ERROR,
  ADD_NEW_ROSTER,
  ADD_NEW_ROSTER_SUCCESS,
  ADD_NEW_ROSTER_ERROR,
  ADD_NEW_ORGANIZATION,
  ADD_NEW_ORGANIZATION_SUCCESS,
  ADD_NEW_ORGANIZATION_ERROR,
  GET_TITLE_ROLE,
  GET_TITLE_ROLE_SUCCESS,
  GET_TITLE_ROLE_ERROR,
  GET_TITLE_ROLE_OPTION,
  GET_TITLE_ROLE_OPTION_SUCCESS,
  GET_TITLE_ROLE_OPTION_ERROR,
  MAP_NEW_TITLE_ROLE,
  MAP_NEW_TITLE_ROLE_SUCCESS,
  MAP_NEW_TITLE_ROLE_ERROR,
  EXPORT_ROSTER,
  EXPORT_ROSTER_SUCCESS,
  EXPORT_ROSTER_RESET,
  EXPORT_ROSTER_ERROR,
  GET_MANAGE_ROLES,
  GET_MANAGE_ROLES_SUCCESS,
  GET_MANAGE_ROLES_ERROR,
  GET_ACTIVE_USERS,
  GET_ACTIVE_USERS_SUCCESS,
  GET_ACTIVE_USERS_ERROR,
} from "../actions";

const INIT_STATE = {
  ActiveUsersList: [],
  OrganizationDetails: [],
  OrganizationRosterData: null,
  ManageRoles: null,
};

const administrationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_DETAILS:
      return {
        ...state,
        OrganizationDetails: null,
        OrganizationLoading: true,
        OrganizationError: "",
      };
    case GET_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        OrganizationDetails: action.payload,
        OrganizationLoading: false,
        OrganizationError: "",
      };
    case GET_ORGANIZATION_DETAILS_ERROR:
      return {
        ...state,
        OrganizationDetails: null,
        OrganizationLoading: false,
        OrganizationError: action.payload.message,
      };
    case GET_ORG_BASED_ROSTER_DATA:
      return {
        ...state,
        OrganizationRosterData: null,
        OrganizationRosterLoading: true,
        OrganizationRosterError: "",
      };
    case GET_ORG_BASED_ROSTER_DATA_SUCCESS:
      return {
        ...state,
        OrganizationRosterData: action.payload,
        OrganizationRosterLoading: false,
        OrganizationRosterError: "",
      };
    case GET_ORG_BASED_ROSTER_DATA_ERROR:
      return {
        ...state,
        OrganizationRosterData: null,
        OrganizationRosterLoading: false,
        OrganizationRosterError: action.payload.message,
      };
    case TOGGLE_ROSTER:
      return {
        ...state,
        toggleRosterData: null,
        toggleRosterError: "",
      };
    case TOGGLE_ROSTER_SUCCESS:
      return {
        ...state,
        toggleRosterData: action.payload,
        toggleRosterError: "",
      };
    case TOGGLE_ROSTER_ERROR:
      return {
        ...state,
        toggleRosterData: null,
        toggleRosterError: action.payload.message,
      };
    case ADD_NEW_ROSTER:
      return {
        ...state,
        addNewRosterData: null,
        addNewRosterError: "",
      };
    case ADD_NEW_ROSTER_SUCCESS:
      return {
        ...state,
        addNewRosterData: action.payload,
        addNewRosterError: "",
      };
    case ADD_NEW_ROSTER_ERROR:
      return {
        ...state,
        addNewRosterData: null,
        addNewRosterError: action.payload.message,
      };
    case ADD_NEW_ORGANIZATION:
      return {
        ...state,
        addNewOrganizationData: null,
        addNewOrganizationLoading: true,
        addNewOrganizationError: "",
      };
    case ADD_NEW_ORGANIZATION_SUCCESS:
      return {
        ...state,
        addNewOrganizationData: action.payload,
        addNewOrganizationLoading: false,
        addNewOrganizationError: "",
      };
    case ADD_NEW_ORGANIZATION_ERROR:
      return {
        ...state,
        addNewOrganizationData: null,
        addNewOrganizationLoading: false,
        addNewOrganizationError: action.payload.message,
      };
    case GET_TITLE_ROLE:
      return {
        ...state,
        getTitleRoleData: null,
        getTitleRoleLoading: true,
        getTitleRoleError: "",
      };
    case GET_TITLE_ROLE_SUCCESS:
      return {
        ...state,
        getTitleRoleData: action.payload,
        getTitleRoleLoading: false,
        getTitleRoleError: "",
      };
    case GET_TITLE_ROLE_ERROR:
      return {
        ...state,
        getTitleRoleData: null,
        getTitleRoleLoading: false,
        getTitleRoleError: action.payload.message,
      };
    case GET_TITLE_ROLE_OPTION:
      return {
        ...state,
        getTitleRoleOptionData: null,
        getTitleRoleOptionLoading: true,
        getTitleRoleOptionError: "",
      };
    case GET_TITLE_ROLE_OPTION_SUCCESS:
      return {
        ...state,
        getTitleRoleOptionData: action.payload,
        getTitleRoleOptionLoading: false,
        getTitleRoleOptionError: "",
      };
    case GET_TITLE_ROLE_OPTION_ERROR:
      return {
        ...state,
        getTitleRoleOptionData: null,
        getTitleRoleOptionLoading: false,
        getTitleRoleOptionError: action.payload.message,
      };
    case MAP_NEW_TITLE_ROLE:
      return {
        ...state,
        mapNewTitleRoleData: null,
        mapNewTitleRoleLoading: true,
        mapNewTitleRoleError: "",
      };
    case MAP_NEW_TITLE_ROLE_SUCCESS:
      return {
        ...state,
        mapNewTitleRoleData: action.payload,
        mapNewTitleRoleLoading: false,
        mapNewTitleRoleError: "",
      };
    case MAP_NEW_TITLE_ROLE_ERROR:
      return {
        ...state,
        mapNewTitleRoleData: null,
        mapNewTitleRoleLoading: false,
        mapNewTitleRoleError: action.payload.message,
      };
    case EXPORT_ROSTER:
      return {
        ...state,
        exportRosterData: null,
        exportRosterLoading: true,
        exportRosterError: "",
      };
    case EXPORT_ROSTER_SUCCESS:
      return {
        ...state,
        exportRosterData: action.payload,
        exportRosterLoading: false,
        exportRosterError: "",
      };
    case EXPORT_ROSTER_RESET:
      return {
        ...state,
        exportRosterData: [],
        personListError: "",
      };
    case EXPORT_ROSTER_ERROR:
      return {
        ...state,
        exportRosterData: null,
        exportRosterLoading: false,
        exportRosterError: action.payload.message,
      };
    case GET_MANAGE_ROLES:
      return {
        ...state,
        ManageRoles: null,
        ManageRolesLoading: true,
        ManageRolesError: "",
      };
    case GET_MANAGE_ROLES_SUCCESS:
      return {
        ...state,
        ManageRoles: action.payload,
        ManageRolesLoading: false,
        ManageRolesError: "",
      };

    case GET_MANAGE_ROLES_ERROR:
      return {
        ...state,
        ManageRoles: null,
        ManageRolesLoading: false,
        ManageRolesError: action.payload.message,
      };
    case GET_ACTIVE_USERS:
      return {
        ...state,
        ActiveUsersList: null,
        ActiveUsersLoading: true,
        ActiveUsersError: "",
      };
    case GET_ACTIVE_USERS_SUCCESS:
      return {
        ...state,
        ActiveUsersList: action.payload,
        ActiveUsersLoading: false,
        ActiveUsersError: "",
      };

    case GET_ACTIVE_USERS_ERROR:
      return {
        ...state,
        ActiveUsersList: null,
        ActiveUsersLoading: false,
        ActiveUsersError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
export default administrationReducer;
