import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { DataEntryAPI } from "../../config/api";
import { toast } from "react-toastify";
import { GET_AUDIT_LOG, GET_TIMEKEEPER_AUDIT_LOG } from "../actions";
import {
  getAuditLogSuccess,
  getAuditLogError,
  getTimekeeperAuditLogSuccess,
  getTimekeeperAuditLogError,
  getTimekeeperAuditLogExportSuccess,
} from "./actions";
import {
  getApiHeader,
  getCompanyGUID,
  getLoginPersonId,
} from "../../helpers/utils";
import axios from "axios";
import moment from "moment";

function* getAuditLogCall({ payload }) {
  try {
    const data = yield call(getAuditLogCallAsync, payload);
    yield put(getAuditLogSuccess(data));
  } catch (error) {
    yield put(getAuditLogError(error));
  }
}
function* getTimekeeperAuditLogCall({ payload }) {
  try {
    const data = yield call(getTimekeeperAuditLogCallAsync, payload);
    if (payload.isExport) {
      yield put(getTimekeeperAuditLogExportSuccess(data));
    } else {
      yield put(getTimekeeperAuditLogSuccess(data));
    }
  } catch (error) {
    yield put(getTimekeeperAuditLogError(error));
  }
}

const getAuditLogCallAsync = (payload) => {
  const searchDate = moment(new Date()).format("YYYY-MM-DD");
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: `${DataEntryAPI}/GetAuditlog?auditdate=${searchDate}&userdapid=${getLoginPersonId()}`,
    data: JSON.stringify(payload),
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
    });
};
const getTimekeeperAuditLogCallAsync = (payload) => {
  let url = `${DataEntryAPI}/GetTimeKeeperAuditLog?companyguid=${getCompanyGUID()}&requestType=${
    payload.requestType
  }&performedAction=${payload.performedAction}&startDate=${
    payload.startDate
  }&endDate=${payload.endDate}`;
  if (payload.searchedPersonId) {
    url += `&searchedPersonId=${payload.searchedPersonId}`;
  }
  return axios({
    method: "GET",
    headers: getApiHeader(),
    url: url,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
    });
};

export function* watchGetAuditLog() {
  yield takeEvery(GET_AUDIT_LOG, getAuditLogCall);
}
export function* watchGetTimekeeperAuditLog() {
  yield takeEvery(GET_TIMEKEEPER_AUDIT_LOG, getTimekeeperAuditLogCall);
}

export default function* rootSaga() {
  yield all([fork(watchGetAuditLog), fork(watchGetTimekeeperAuditLog)]);
}
